export const billingModeWithoutSAP = {
  MAIA_BILLING_LEVEL_1: 'MAIA_BILLING_LEVEL_1',
  MAIA_BILLING_LEVEL_2: 'MAIA_BILLING_LEVEL_2',
  MAIA_BILLING_LEVEL_3: 'MAIA_BILLING_LEVEL_3',
  DIRECT_BILLING: 'DIRECT_BILLING',
}

export const billingModeWithoutSAPLabels = {
  [billingModeWithoutSAP.DIRECT_BILLING]: '[4bis] Facture directe',
  [billingModeWithoutSAP.MAIA_BILLING_LEVEL_1]: '[3bis] Facture Maia, Devis - Facture - Recouvrement (FSC 3%)',
  [billingModeWithoutSAP.MAIA_BILLING_LEVEL_2]:
    '[3bis] Facture Maia, Devis - Facture - Relance devis - Recouvrement (FSC 4%)',
  [billingModeWithoutSAP.MAIA_BILLING_LEVEL_3]:
    '[3bis] Facture Maia, Devis - Facture - Relance devis - Recouvrement - Secrétariat téléphonique (FSC 5%)',
}

export function getOfferFromBillingMode(billingMode) {
  switch (billingMode) {
    case billingModeWithoutSAP.MAIA_BILLING_LEVEL_1:
    case billingModeWithoutSAP.MAIA_BILLING_LEVEL_2:
    case billingModeWithoutSAP.MAIA_BILLING_LEVEL_3:
      return '3bis'
    case billingModeWithoutSAP.DIRECT_BILLING:
      return '4bis'
    default:
      return null
  }
}

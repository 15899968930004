import React, { useState } from 'react'
import { Button, useNotify, useRefresh } from 'react-admin'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import IconCancel from '@material-ui/icons/Cancel'
import TextField from '@material-ui/core/TextField'
import AddCircle from '@material-ui/icons/AddCircle'
import SendIcon from '@material-ui/icons/Send'
import dataProvider from '../../helpers/dataProvider'

const CreatePartnerAccountModal = ({ record }) => {
  const [showDialog, setShowDialog] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const refresh = useRefresh()
  const notify = useNotify()

  const handleClick = () => {
    setShowDialog(true)
  }

  const handleCloseClick = () => {
    setShowDialog(false)
  }

  const enableButtonSend = email.length > 4 && password.length > 4 && !loading

  const handleSendClick = () => {
    setLoading(true)
    dataProvider
      .post(`/professionals/${record.id}/partner`, {
        email,
        password,
      })
      .then((response) => {
        if (response.status === 200) {
          notify('Le compte partenaire a bien été créé', 'info')
          setEmail('')
          setPassword('')
          setShowDialog(false)
          refresh()
        } else {
          notify("Erreur: le compte partenaire n'a pas pu être créé", 'error')
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }
  const addRewardPointsDialogView = () => (
    <Dialog
      open={showDialog}
      style={{
        paddingBottom: '4em',
      }}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>Créer un compte partenaire</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="email"
          label="Email"
          type="email"
          fullWidth
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          style={{ marginBottom: '30px' }}
        />
        <TextField
          margin="dense"
          id="name"
          label="Mot de passe"
          type="text"
          fullWidth
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          style={{ marginTop: '30px' }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseClick} label="Fermer" disabled={loading}>
          <IconCancel />
        </Button>
        <Button onClick={handleSendClick} label="Créer" disabled={!enableButtonSend}>
          <SendIcon />
        </Button>
      </DialogActions>
    </Dialog>
  )

  const result = () => (
    <>
      <Button onClick={handleClick} label="Créer un compte partenaire">
        <AddCircle />
      </Button>
      {addRewardPointsDialogView()}
    </>
  )

  return result()
}

export default CreatePartnerAccountModal

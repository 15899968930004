import React from 'react'
import {
  Datagrid,
  DateField,
  DateInput,
  EditButton,
  EmailField,
  Filter,
  List,
  ReferenceField,
  TextField,
  TextInput,
  TopToolbar,
} from 'react-admin'
import ExportModalView from '../../export/ExportModalView'

const CustomerFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Nom" source="qName" alwaysOn />
    <TextInput label="Téléphone" source="qPhone" alwaysOn />
    <TextInput label="Email" source="qEmail" alwaysOn />
    <TextInput label="Code postal" source="qZipcode" alwaysOn />
    <DateInput label="Début" source="start" alwaysOn />
    <DateInput label="Fin" source="end" alwaysOn />
  </Filter>
)

const ExportActions = () => (
  <TopToolbar>
    <ExportModalView path="customers" />
  </TopToolbar>
)

const CustomerList = (props) => (
  <List
    {...props}
    actions={<ExportActions />}
    sort={{ field: 'createdDate', order: 'DESC' }}
    filters={<CustomerFilter />}
  >
    <Datagrid rowClick="show">
      <TextField source="profile.phone" label="Téléphone" />
      <DateField locales="fr-FR" source="createdDate" label="Date de création" />
      <ReferenceField label="Nom" link="show" source="id" reference="customers">
        <TextField source="profile.lastName" />
      </ReferenceField>
      <ReferenceField label="Prénom" link="show" source="id" reference="customers">
        <TextField source="profile.firstName" />
      </ReferenceField>
      <TextField source="customer.status" label="État" />
      <EmailField source="account.email" label="Mail" />
      <ReferenceField label="Parrain" link="show" source="relatedProfessional" reference="professionals">
        <TextField source="profile.fullname" />
      </ReferenceField>
      <TextField source="customer.address.zipCode" label="CP" />
      <TextField source="customer.address.city" label="Ville" />
      <EditButton />
    </Datagrid>
  </List>
)

export default CustomerList

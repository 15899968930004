export const customer1bisType = {
  OFFER1BIS: 'OFFER1BIS',
  OFFER2BIS: 'OFFER2BIS',
  NO_SAP: 'NO_SAP',
}

export const customer1bisTypeLabels = {
  [customer1bisType.OFFER1BIS]: '1bis',
  [customer1bisType.OFFER2BIS]: '2bis',
  [customer1bisType.NO_SAP]: 'Sans SAP (3bis/4bis)',
}
